<template>
  <div>
    <TypePaiementEditor
      :type-paiement="selectedTypePaiement"
      :visible="typePaimentModalVisible"
      :processing="typePaimentProcessing"
      @close="handleTypePaiementFormClosing"
      @submit="handleTypePaiementFormSubmit"
      :key="newTypePaiementComponentKey"
      ref="typePaiementForm"
    />
    <h1 style="margin-left:0%;margin-bottom:4%">Type de paiements</h1>
    <a-row type="flex" justify="space-between" class="mrgin_btm">
      <a-col :span="8">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="addTypePaiement"
          >Ajouter type paiement</a-button
        >
      </a-col>
      <a-col :span="8" class="mrgin_btm txt-end">
        <search-component
          v-on:change="searchAdherent($event)"
        ></search-component>
      </a-col>
    </a-row>

    <a-spin :spinning="processing">
      <a-table
        bordered
        v-if="type_paiements.length"
        :dataSource="type_paiements"
        :columns="columns"
        :rowKey="
          (record) => {
            return record.id;
          }
        "
        :pagination="false"
      >
        <template slot="row_index" slot-scope="text, record, index">
          <p>{{ index + 1 }}</p>
        </template>

        <template
          class="table-operation"
          slot="operation"
          slot-scope="text, record"
        >
          <a-popconfirm
            v-if="type_paiements.length"
            title="Etes-vous sûr de vouloir supprimer cette ligne ?"
            @confirm="() => onDelete(record.id)"
          >
            <a-tooltip placement="top">
              <template slot="title">
                <span>Suppression</span>
              </template>
              <a-icon
                type="delete"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Edition</span>
            </template>
            <a-icon
              type="edit"
              v-on:click="handleEdit(record)"
              class="action_icons"
              style="margin-right:10px;"
              theme="twoTone"
              twoToneColor="#52c41a"
            />
          </a-tooltip>
        </template>
      </a-table>
      <EmptyResult v-else />
      <a-pagination
        v-if="type_paiements.length"
        style="margin-top:1%"
        @change="onPaginationChange"
        @showSizeChange="onPageSizeChange"
        :current="pagination.current"
        :total="total"
        :showTotal="(total) => `Total : ${total}`"
        :pageSize="pagination.pageSize"
        :showSizeChanger="true"
      />
    </a-spin>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import debounce from "lodash-es/debounce";
import SearchComponent from "@/components/common/Search";
import EmptyResult from "@/components/common/EmptyResult";
import TypePaiementEditor from "./TypePaiementEditor";

const columns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Nom fr",
    dataIndex: "nom_fr",
  },
  {
    title: "Nom ar",
    dataIndex: "nom_ar",
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "TypePaiementsList",
  components: {
    SearchComponent,
    EmptyResult,
    TypePaiementEditor,
  },
  created() {
    this.fetchData();
  },
  data() {
    this.searchAdherent = debounce(this.searchAdherent, 500);
    return {
      processing: true,
      query: "",
      columns,
      selectedTypePaiement: null,
      typePaimentModalVisible: false,
      typePaimentProcessing: false,
      newTypePaiementComponentKey: 0,
    };
  },
  computed: {
    ...mapState({
      type_paiements: (state) => state.type_paiements.type_paiements,
      total: (state) => state.type_paiements.pagination.total,
      pagination: (state) => state.type_paiements.pagination,
    }),
  },
  methods: {
    fetchData() {
      this.startProcessing();
      this.getData({ q: this.query })
        .finally(() => this.stopProcessing());
    },
    onPaginationChange(pagination) {
      this.setPagination({ ...this.pagination, current: pagination });
      this.fetchData();
    },
    onPageSizeChange(current, size) {
      const pager = { ...this.pagination };
      pager.pageSize = size;
      this.setPagination(pager);
      this.fetchData();
    },
    generateNewTypeComponenetKey() {
      this.newTypePaiementComponentKey = this.getRandomInt();
    },
    handleTypePaiementFormSubmit() {
      const form = this.$refs["typePaiementForm"].form;
      let that = this;
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.startTypePaiementFormProcessing();
          if (this.selectedTypePaiement) {
            this.update({ id: this.selectedTypePaiement.id, ...values })
              .then((response) => {
                that.stopTypePaiementFormProcessing();
                form.resetFields();
                that.clearSelectedTypePaiement();
                that.closeTypePaiementModal();
                that.fetchData();
              })
              .catch((err) => {
                that.stopTypePaiementFormProcessing();
              });
            return;
          }
          this.add({
            ...values,
          })
            .then((response) => {
              that.stopTypePaiementFormProcessing();
              form.resetFields();
              that.clearSelectedTypePaiement();
              that.closeTypePaiementModal();
              that.fetchData();
            })
            .catch((err) => {
              that.stopTypePaiementFormProcessing();
            });
        } else {
          that.stopTypePaiementFormProcessing();
        }
      });
    },
    handleTypePaiementFormClosing() {
      this.closeTypePaiementModal();
    },
    clearSelectedTypePaiement() {
      this.selectedTypePaiement = null;
    },
    closeTypePaiementModal() {
      this.typePaimentModalVisible = false;
    },
    openTypePaiementModal() {
      this.typePaimentModalVisible = true;
    },
    searchAdherent(e) {
      this.query = e;
      this.fetchData();
    },
    addTypePaiement() {
      this.generateNewTypeComponenetKey();
      this.openTypePaiementModal();
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startTypePaiementFormProcessing() {
      this.typePaimentProcessing = true;
    },
    stopTypePaiementFormProcessing() {
      this.typePaimentProcessing = false;
    },
    onDelete(id) {
      this.remove(id)
        .then(() => this.fetchData())
        .catch(() => this._throwAnError());
    },
    handleEdit(typePaiment) {
      this.selectedTypePaiement = typePaiment;
      this.generateNewTypeComponenetKey();
      this.openTypePaiementModal();
    },
    ...mapMutations({
      setPagination: "setTypePaiementsPagination",
      resetPagination: "setTypePaiementsDefaultPagination",
    }),
    ...mapActions({
      getData: "fetchTypePaiements",
      add: "storeTypePaiement",
      update: "updateTypePaiement",
      remove: "destroyTypePaiement",
    }),
  },
};
</script>
<style scoped>
td {
  width: max-content;
}

.decision {
  text-decoration: underline;
  margin-left: 3%;
}

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}
.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 20px !important;
}

.decission_btn {
  text-decoration: underline;
}

.action_icons {
  margin: 4px;
  font-size: large;
}
.mrgin_btm {
  margin-bottom: 2%;
}
.txt-end {
  text-align: end;
}
</style>
