var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", align: "middle" } },
    [_c("a-col", { attrs: { span: 19 } }, [_c("TypePaiementsList")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }