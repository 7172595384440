<template>
  <a-row type="flex" align="middle">
    <a-col :span="19">
        <TypePaiementsList />
    </a-col>
  </a-row>
</template>
<script>
import TypePaiementsList from "./TypePaiementsList";
export default {
  name: "TypePaiements",
  components: { TypePaiementsList },
};
</script>
